import React from 'react';
import { Link } from 'gatsby';

import {
  sortByYear,
  isLongTitle,
  isExternalLink,
} from '../utils/linksUtils';
import { linkData } from '../data/linkData';

const Links = () => (
  <ul>
    {linkData.sort(sortByYear).map(({ linkText, href }) => {
      const className = isLongTitle(linkText) ? 'longTitle' : '';
      return (
        <li key={linkText}>
          {isExternalLink(href) ? (
            <a
              className={className}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
          ) : (
            <Link
              className={className}
              to={href}
            >
              {linkText}
            </Link>
          )}
        </li>
      )
    })}
  </ul>
);

export {
  Links,
}
