const linkData: LinkDatum[] = [
  {
    linkText: 'Jessica',
    href: 'https://zco.mx/JasonOverby/Jessica',
    year: 2008,
  },
  {
    linkText: '2101',
    href: 'http://twentyonezeroone.com/',
    year: 2011,
  },
  {
    linkText: 'Solipsist\'s Doodles',
    href: '/solipsists-doodles/page01',
    totalPages: 30,
    year: 2008,
  },
  {
    linkText: 'Compression',
    href: 'http://disguisekit.files.wordpress.com/2012/11/compression-1-2.pdf',
    year: 2009,
  },
  {
    linkText: 'Yoko Ono in the Curse of Hall Hassi',
    href: 'http://disguisekit.files.wordpress.com/2011/10/yokoono.pdf',
    year: 2010,
  },
  {
    linkText: 'Obligatory Artifact',
    href: 'http://disguisekit.files.wordpress.com/2010/12/readobligatoryartifact.pdf',
    year: 2010,
  },
  {
    linkText: 'Shark',
    href: 'http://disguisekit.files.wordpress.com/2010/07/shark.pdf',
    year: 2010,
  },
];

export { linkData };
