import React from 'react';
import { Links } from '../components/links';
import Layout from '../components/layout';

const Index = () => {
  return (
    <Layout>
      <Links />
    </Layout>
  );
};

export default Index;
